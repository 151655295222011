/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  height: 72px;
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  background: $white;

  &.header-scrolled {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.10);
  }

  .logo {

    h1 {
      font-size: 28px;
      margin: 0;
      line-height: 0;
      font-weight: 600;
      letter-spacing: 1px;
      font-family: $font-default;
    }

    h1 a, h1 a:hover {
      color: lighten($secondary, 15);
      text-decoration: none;
    }

    img {
      padding: 0;
      margin: 0;
      max-height: 40px;
    }
  }
}

/*--------------------------------------------------------------
# Header Social Links
--------------------------------------------------------------*/
.header-social-links {
  margin: 0 0 0 30px;
  @media (max-width: 768px) {
    margin: 0 15px 0 0;
  }

  a {
    color: lighten($secondary, 30);
    display: inline-block;
    line-height: 0px;
    transition: 0.3s;
    padding-left: 10px;
    &:hover {
      // color: $primary;
      color: var(--nepro-text-dark);
    }
  }

}
