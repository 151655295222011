@import 'variables';

@import 'general';
@import 'header';
@import 'nav';
@import 'hero';
@import 'sections';
@import 'footer';

ion-content {
  --background: white; // Replace this with color of your choice
}