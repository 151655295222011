/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: lighten($secondary, 73);
  padding: 0 0 30px 0;
  color: $secondary;
  font-size: 14px;


  .footer-top {
    background: $white;
    padding: 60px 0 30px 0;
    border-top: 2px solid lighten($secondary, 68);
    border-bottom: 2px solid lighten($secondary, 68);

    .footer-info {
      margin-bottom: 30px;
      h3 {
        font-size: 24px;
        margin: 0 0 20px 0;
        padding: 2px 0 2px 0;
        line-height: 1;
        font-weight: 700;
      }

      p {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0;
        font-family: $font-primary;
        color: lighten($secondary, 15);
      }
    }

    .social-links {
      a {
        font-size: 18px;
        display: inline-block;
        background: lighten($secondary, 68);
        color: lighten($secondary, 35);
        line-height: 1;
        padding: 8px 0;
        margin-right: 4px;
        border-radius: 50%;
        text-align: center;
        width: 36px;
        height: 36px;
        transition: 0.3s;
        &:hover {
          //background: $primary;
          background: var(--nepro-text-dark);
          color: #fff;
          text-decoration: none;
        }
      }
    }

    h4 {
      font-size: 16px;
      font-weight: 600;
      color: $secondary;
      position: relative;
      padding-bottom: 12px;
    }

    .footer-links {
      margin-bottom: 30px;
      ul{
        list-style: none;
        padding: 0;
        margin: 0;

        i {
          padding-right: 2px;
          //color: $primary;
          color: var(--nepro-text-dark);
          font-size: 18px;
          line-height: 1;
          margin-left: -5px;
        }

        li {
          padding: 10px 0;
          &:first-child {
            padding-top: 0;
          }
          display: flex;
          align-items: center;
        }

        a {
          color: lighten($secondary, 15);
          transition: 0.3s;
          display: inline-block;
          line-height: 1;
          &:hover {
            //color: $primary;
            color: var(--nepro-text-dark);
          }
        }
      }
    }

    .footer-newsletter {
  
      form {
        margin-top: 30px;
        background: #fff;
        padding: 6px 10px;
        position: relative;
        border-radius: 4px;
        border: 1px solid lighten($secondary, 66);
  
        input[type="email"] {
          border: 0;
          padding: 4px;
          width: calc(100% - 110px);
          background: var(--nepro-background);
        }
        input[type="submit"] {
          position: absolute;
          top: -1px;
          right: -2px;
          bottom: -1px;
          border: 0;
          background: none;
          font-size: 16px;
          padding: 0 20px;
          //background: $primary;
          background: var(--nepro-text-dark);
          color: $white;
          transition: 0.3s;
          border-radius: 0 4px 4px 0;
          &:hover {
            background: darken($primary, 10%);
          }
        }
      }
    }

  }

  .copyright {
    text-align: center;
    padding-top: 30px;
  }

  .credits {
    padding-top: 8px;
    text-align: center;
    font-size: 13px;
    color: $secondary;
  }
}
